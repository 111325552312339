/*
* 업무구분: 고객
* 화면 명: MSPCM344P
* 화면 설명: 급부별 상품보장금액
* 화면 접근권한: 일반사용자
*/
<template>
   <ur-page-container class="msp" title="급부별 상품보장금액" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start" style="max-height: 600px;">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container align-v="center" componentid="" direction="row" ref="_modal__title" class="ns-bs-title-login">
          <div>
            <ur-text class="untact-txt" size="sm" style="color: #6B7684">고객명</ur-text><!-- style 추가 2024.9.4 :chan -->
            <ur-text class="popupCustomerName">{{ params.custNm }}</ur-text><!-- class 추가 2024.9.2 :chan -->
          </div>
          <div class="btn-close" @click="$emit('cancelPopup')">X</div>
        </ur-box-container>

        <ur-box-container v-if="attr" alignV="start" componentid="" direction="row" style="padding: 0 24px; gap: 20px;">
          <div class="fexTy1">
            <div style="display: inline-block; width: 16px; height: 16px;" :style="{ background: attr.background }"></div>
            &nbsp;
            <span style="font-weight: bold;">{{ attr.name }}</span>
          </div>
          <div class="fexTy1">
            <div style="display: inline-block; width: 16px; height: 16px;" :style="{ background: attr.children[0].background }"></div>
            &nbsp;
            <span>{{ attr.children[0].name }}</span>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" style="padding: 20px 0;">
          <div class="fex-clumn pa2024 bd-b-Ty10 fwb" style="display: flex;">
            <span class="fs16rem">{{ attr ? attr.children[0].children[0].name : '총 보험료' }}</span>
            <span class="fs24rem">{{ params.price }}만원</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
            <mo-list :list-data="listVO">
              <template #list-item="{ item }">
                <div class="list-item w100 pa2024 bd-b-Ty10">
                  <div class="list-item__contents">
                    <div class="list-item__contents__title fwn">
                      <span class="name fs17rem">{{ item.kcisPrdtNm }}</span>
                      <mo-badge v-if="item.kcisCoCd === 'L03'" class="badge-sample-badge lightblue sm white-space-nowrap" text="당사" shape="status">당사</mo-badge>
                      <mo-badge v-else class="badge-sample-badge lightgray2 sm white-space-nowrap" text="타사" shape="status">타사</mo-badge>
                    </div>
                    <div class="list-item__contents__title fwn">
                      <span class="name fs14rem">{{ item.coNm }}</span>
                      <strong>{{ $commonUtil.numberWithCommas(attr ? item.coltrAmt : item.padPrm) }}만원</strong>
                    </div>
                  </div>
                </div>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
        
        <ur-box-container alignV="start" componentid="ur_box_container_019" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="$emit('confirmPopup')" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
          <div class="ns-height80"></div>
        </ur-box-container>
        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  import cmConstants from '@/config/constants/cmConstants'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM344P",
    screenId: "MSPCM344P",
    props: {
      params: {
        type: Object,
        default: () => {}
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SelCustprdtCoverAmtList();
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        listVO: [], // 조회용 Service IF VO
      };
    },
    /** computed 함수 정의 영역 */
    computed: {
      attr() {
        if (!this.params.attrNm) return null;

        return _.cloneDeep(cmConstants.BENF_INFO_GRID).reduce((acc, cur) => {
          cur.children = cur.children.reduce((acc2, cur2) => {
            cur2.children = cur2.children.filter(v => v.value === this.params.attrNm);
            return cur2.children.length ? [...acc2, cur2] : acc2;
          }, [])
          return cur.children.length ? [...acc, cur] : acc;
        }, [])[0];
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_SelCustprdtCoverAmtList() {
        const trnstId = "txTSSCM17S2";
        const auth = "S";
        let pParams = {
          cnsltNo: this.params.cnsltNo,
          chnlCustId: this.params.chnlCustId,
          attrNm: this.params.attrNm,
        };

        this.post(this, pParams, trnstId, auth)
          .then((response) => {
            if (response.body) {
              this.listVO = response.body.iCPrdtCovrAmt;
            }
          })
          .catch((error) => {
            this.getStore("confirm").dispatch("ADD", error);
            window.vue.error(error);
          });
      },
    }
  }
</script>