/*
* 업무구분: 고객
* 화면 명: MSPCM345P
* 화면 설명: 컨설턴트 필터
* 화면 접근권한: 일반사용자
*/
<template>
   <ur-page-container class="msp" title="컨설턴트" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container align-v="center" componentid="ur_box_container_001" direction="row" ref="_modal__title" class="ns-bs-title-login">
        <div class="title"><ur-text size="lg">컨설턴트</ur-text></div>
        <div class="btn-close" @click="$emit('cancelPopup')">X</div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <SearchCondOrgCM ref="refSearchOrg" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData" @selectResult="lv_SelectOrg = $event"/>     
      </ur-box-container>
      
      <ur-box-container align-v="start" componentid="ur_box_container_003" direction="row" align-h="end" class="ns-btn-relative-bs">
        <div class="relative-div">
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPop" class="ns-btn-round blue">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MSPBottomAlert from "@/ui/_cmm/components/MSPBottomAlert";

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM345P",
  screenId: "MSPCM345P",
  components: {
    SearchCondOrgCM
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_SelectOrg: {}, // 조직 컴포넌트 데이터
      pOrgData: {}, // 조직 정보
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.pOrgData = this.params.pOrgData;
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {   
    fn_ConfirmPop() {
      if (this.lv_SelectOrg.cnslt.key === '0') {
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            showCloseBtn: false,
            title: "",
            content: '담당 컨설턴트는 필수로 지정하셔야 합니다.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop);
            },
          }
        })
        return;
      }
      this.$emit('confirmPopup', this.lv_SelectOrg);
    },
  }
}
</script>