/*
* 업무구분: 고객
* 화면 명: MSPCM342P
* 화면 설명: 회사 필터
* 화면 접근권한: 일반사용자
*/
<template>
   <ur-page-container class="msp" title="회사" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start" style="height: 600px;">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container align-v="center" componentid="" direction="row" ref="_modal__title" class="ns-bs-title-login">
          <div class="title"><ur-text size="lg">회사</ur-text></div>
          <div class="btn-close" @click="$emit('cancelPopup', originKcisCoCds)">X</div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
          <mo-list-item style="border: none; margin-bottom: 10px;"><!-- 2024.9.2 :chan -->
            <mo-checkbox v-model="isCheckAll" @input="fn_CheckAll">
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span style="font-size: 1.1875rem; font-weight: normal">전체</span><!-- 2024.9.2 :chan -->
                </div>
            </div>
            </mo-checkbox>
          </mo-list-item>
        </ur-box-container>
        <div class="popupContentUpLine"><!-- 2024.9.2 :chan -->
          <div></div>
        </div>
        
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-input-box mt30">
          <ur-box-container v-for="(category, idx) of kcisCoCds" :key="idx" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-chk-box3 mb45">
            <div class="ns-check flex-col">
              <mo-checkbox v-if="category.text" v-model="category.checked" size="small" @input="category.children.forEach(v => { $set(v, 'checked', $event) })">
                <strong class="fs19">{{ category.text }}</strong>
              </mo-checkbox>
              <div style="display: flex; flex-wrap: wrap; column-gap: 18px;">
                <mo-checkbox v-for="(item, idx2) in category.children" :key="`${idx}-${idx2}`" v-model="item.checked" size="small" @input="fn_InputCheckbox(category, $event)">
                  <span class="fs16">{{ item.text }}</span>
                </mo-checkbox>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
        
        <ur-box-container alignV="start" componentid="ur_box_container_019" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="$emit('confirmPopup', isCheckAll ? [] : selectedItem)" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
          <div class="ns-height80"></div>
        </ur-box-container>
        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM342P",
    screenId: "MSPCM342P",
    props: {
      params: {
        type: Object,
        default: () => {}
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.kcisCoCds = this.params.kcisCoCds;
      this.originKcisCoCds = _.cloneDeep(this.params.kcisCoCds);
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isCheckAll: true,
        kcisCoCds: [],
        originKcisCoCds: []
      };
    },
    /** computed 함수 정의 영역 */
    computed: {
      selectedItem() {
        return this.kcisCoCds.reduce((acc, cur) => [...acc, ...cur.children.filter(v => v.checked)], []);
      },
    },
    watch: {
      selectedItem(val) {
        this.isCheckAll = val.length === this.kcisCoCds.reduce((acc, cur) => [...acc, ...cur.children], []).length;
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_CheckAll(e) {
        this.kcisCoCds.forEach(category => {
          this.$set(category, 'checked', e);
          category.children.forEach(item => { this.$set(item, 'checked', e) });
        });
      },
      fn_InputCheckbox(data, isCheck) {
        if (!data.text) return;
        
        if (isCheck) {
          data.checked = data.children.length === data.children.filter(v => v.checked).length;
        } else {
          data.checked = false;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .mo-checkbox {
  flex: none;
  width: auto !important;
}
</style>