/*
* 업무구분: 고객
* 화면 명: MSPCM343P
* 화면 설명: 보장 필터
* 화면 접근권한: 일반사용자
*/
<template>
   <ur-page-container class="msp" title="보장" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start" style="height: 600px;">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container align-v="center" componentid="" direction="row" ref="_modal__title" class="ns-bs-title-login">
          <div class="title"><ur-text size="lg">보장</ur-text></div>
          <div class="btn-close" @click="$emit('cancelPopup', originBenfInfo)">X</div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
          <p class="untact-txt fs14 crTy-bk7" style="margin-top: 0; padding: 0 20px 10px; line-height: normal;">
            ※ 일부 보장급부는 신용정보원에 없는 데이터로 추후 추가반영 예정입니다.<br />
            (예시: 부정맥, 상급병원암통원 등)
          </p>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
          <mo-list-item style="border: none; margin-bottom: 10px;">
            <mo-checkbox v-model="isCheckAll" @input="fn_CheckAll">
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span style="font-size: 1.1875rem; font-weight: normal">전체</span>
                </div>
              </div>
            </mo-checkbox>
          </mo-list-item>
        </ur-box-container>
        <div class="popupContentUpLine"><!-- 2024.9.2 :chan -->
          <div></div>
        </div>
        
        <ur-box-container v-for="(parent, idx1) of benfInfo" :key="`parent${idx1}`" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-input-box" :class="{ 'mt30': idx1 === 0 }">
          <span class="ns-title fs19" style="margin-bottom: 10px;">{{ parent.name }}</span>

          <ur-box-container v-for="(child, idx2) of parent.children" :key="`child${idx1}-${idx2}`" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-chk-box3 mb20">
            <div class="ns-check flex-col pl15">
              <mo-checkbox v-model="child.checked" size="small" @input="child.children.forEach(v => { $set(v, 'checked', !v.disabled ? $event : false) })">
                <strong class="fs16">{{ child.name }}</strong>
              </mo-checkbox>
              <div style="display: flex; flex-wrap: wrap; column-gap: 18px;">
                <mo-checkbox v-for="(item, idx3) in child.children" :key="`item${idx1}-${idx2}-${idx3}`" v-model="item.checked" size="small" :disabled="item.disabled" @input="fn_InputCheckbox(child, $event)">
                  <span class="fs16">{{ item.name }}</span>
                </mo-checkbox>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
        
        <ur-box-container alignV="start" componentid="ur_box_container_019" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPop" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
          <div class="ns-height80"></div>
        </ur-box-container>
        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM343P",
    screenId: "MSPCM343P",
    props: {
      params: {
        type: Object,
        default: () => {}
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.benfInfo = this.params.benfInfo;
      this.originBenfInfo = _.cloneDeep(this.params.benfInfo);
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isCheckAll: false,
        benfInfo: [],
        originBenfInfo: [],
      };
    },
    /** computed 함수 정의 영역 */
    computed: {
      selectedItemFlat() {
        return this.fn_RecursiveFlattenTree(this.benfInfo).filter(v => v.checked);
      },
    },
    watch: {
      selectedItemFlat(val) {
        const totalCount = this.fn_RecursiveFlattenTree(this.benfInfo).length;
        this.isCheckAll = val.length === totalCount;
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_RecursiveFlattenTree(tree, flat = []) {
        tree.forEach(item => {
          if (item.children) this.fn_RecursiveFlattenTree(item.children, flat);
          else if (!item.disabled) flat.push(item);
        });
        return flat;
      },
      fn_CheckAll(e) {
        this.benfInfo.forEach(parent => {
          parent.children.forEach(child => {
            this.$set(child, 'checked', e)
            child.children.forEach(item => {
              this.$set(item, 'checked', !item.disabled ? e : false)
            })
          })
        });
      },
      fn_InputCheckbox(data, isCheck) {
        if (isCheck) {
          data.checked = data.children.filter(v => !v.disabled).length === data.children.filter(v => v.checked).length;
        } else {
          data.checked = false;
        }
      },
      fn_ConvertCheckedTree(tree) {
        return _.cloneDeep(tree).reduce((acc, cur) => {
          cur.children = cur.children.reduce((acc2, cur2) => {
            cur2.children = cur2.children.filter(v => v.checked && !v.disabled);
            return cur2.children.length ? [...acc2, cur2] : acc2;
          }, [])
          return cur.children.length ? [...acc, cur] : acc;
        }, []);
      },
      fn_ConfirmPop() {
        this.$emit('confirmPopup', {
          flat: this.selectedItemFlat.reduce((acc, cur) => [...acc, cur], []),
          tree: this.fn_ConvertCheckedTree(this.benfInfo),
          checkAll: this.isCheckAll
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .mo-checkbox {
  flex: none;
  width: auto !important;
}
</style>